import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./private-route";

import { LoginPage } from "./login/ui";
import { UserPage } from "./users/ui";
import { WithdrawPage } from "./withdraw/ui";
import { AdminLayout } from "widgets/admin-layout";

export const Routing = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" WrapComponent={AdminLayout} />
        }
      >
        <Route path="users" Component={UserPage}></Route>
        <Route path="withdraw" Component={WithdrawPage}></Route>
        <Route path="*" element={<Navigate to="/users" />} />
      </Route>
      <Route path="/login" Component={LoginPage}></Route>
      <Route path="*" element={<Navigate to="/users" />} />
    </Routes>
  );
};
