import { Layout, Space } from "antd";
import { Header } from "widgets/header";

import { ViewerCard } from "features/viewer/ui/viewer-card";
import { AdminMenu } from "widgets/horizontal-menu";
import { LogoutButton } from "features/viewer/ui/logout-button";

const { Content, Sider } = Layout;

export function AdminLayout(props: React.PropsWithChildren) {
  return (
    <div style={{ height: "100vh", backgroundColor: "#41899F" }}>
      <Layout style={{ backgroundColor: "#41899F" }}>
        <Sider
          width={252}
          style={{
            backgroundColor: "#41899F",
            height: "100vh",
            overflow: "auto",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              padding: "32px 45px",
            }}
          >
            <Space
              direction="vertical"
              size={64}
              style={{ width: "100%" }}
              align="center"
            >
              <ViewerCard />
              <AdminMenu />
            </Space>
            <LogoutButton />
          </div>
        </Sider>
        <Layout
          style={{
            margin: "18px 18px 18px 252px",
            paddingBottom: "32px",
            borderRadius: "16px 16px 16px 16px",
            backgroundColor: "#EBF5F9",
          }}
        >
          <Header></Header>
          <Content style={{ padding: "0 36px" }}>{props.children}</Content>
        </Layout>
      </Layout>
    </div>
  );
}
