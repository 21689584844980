import { createSlice } from "@reduxjs/toolkit";

const viewerSlice = createSlice({
  name: "viewer",
  initialState: () => {
    const state = {
      profile: {
        admin_id: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: "",
      },
      jwt: {
        token: "",
        refreshToken: "",
      },
      profile_loaded: false,
    };

    state.jwt.token = localStorage.getItem("token") || "";
    state.jwt.refreshToken = localStorage.getItem("refreshToken") || "";

    return state;
  },

  reducers: {
    setViewerProfile: (state, action) => {
      const { admin_id, email, firstname, lastname, phone } = action.payload;

      state.profile.admin_id = admin_id;
      state.profile.email = email;
      state.profile.firstname = firstname;
      state.profile.lastname = lastname;
      state.profile.phone = phone;

      state.profile_loaded = true;
    },
    setViewerTokens: (state, action) => {
      const { refreshToken, token } = action.payload;

      state.jwt.token = token;
      state.jwt.refreshToken = refreshToken;

      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
    },
    resetToInitialState: (state) => {
      state = {
        profile: {
          admin_id: "",
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        },
        jwt: {
          refreshToken: "",
          token: "",
        },
        profile_loaded: false,
      };

      localStorage.clear();

      return state;
    },
  },
});

export default viewerSlice.reducer;

export const { setViewerProfile, setViewerTokens, resetToInitialState } =
  viewerSlice.actions;
