import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./privateApi";

export const withdrawApi = createApi({
  reducerPath: "withdrawApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["withdraw"],
  endpoints: (builder) => ({
    getWithdrawList: builder.query<any, { offset: number }>({
      query: ({ offset }) => ({
        url: `admin/withdraw/list`,
        method: "POST",
        body: {
          limit: 20,
          offset,
          order: {
            created_at: -1,
          },
        },
      }),
      providesTags: (result) => {
        return [{ type: "withdraw", id: "list" }];
      },
    }),
    setWithdrawStatus: builder.mutation<any, { withdraw_id: string }>({
      query: ({ withdraw_id }: { withdraw_id: string }) => ({
        url: `admin/withdraw/confirm/${withdraw_id}`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { withdraw_id }) => {
        return [{ type: "withdraw", id: "list" }];
      },
    }),
  }),
});

export const { useGetWithdrawListQuery, useSetWithdrawStatusMutation } =
  withdrawApi;
