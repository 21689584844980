import { configureStore } from "@reduxjs/toolkit";
import { api } from "shared/api/api";

import viewer from "entities/viewer/slice";
import auth from "entities/auth/slice";
import withdraw from "entities/withdraw/slice";

import { privateApi } from "shared/api/privateApi";
import { withdrawApi } from "shared/api/withdrawApi";

export const store = configureStore({
  reducer: {
    viewer,
    auth,
    withdraw,
    [api.reducerPath]: api.reducer,
    [privateApi.reducerPath]: privateApi.reducer,
    [withdrawApi.reducerPath]: withdrawApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(privateApi.middleware)
      .concat(withdrawApi.middleware),
});
