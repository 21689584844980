// import "./App.css";

import { ConfigProvider } from "antd";
import { Routing } from "pages";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./providers/store";
import { Profiler } from "react";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: { fontFamily: "PT Sans" },
        components: {
          Table: {
            headerBg: "#ffffff",
            cellFontSize: 16,
            cellPaddingBlock: 22,
          },
          Button: {
            contentFontSize: 16,
            paddingInline: 16,
          },
        },
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
