import { Menu, Space, Row } from "antd";
import { TeamOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { MenuItem } from "./menu-items";

const items = [
  {
    key: "users",
    label: "Данные пользователeй",
    icon: <TeamOutlined style={{ fontSize: "24px" }} />,
    link: "/users",
  },
  {
    key: "withdraw",
    label: "Запросы на вывод",
    icon: <ArrowRightOutlined style={{ fontSize: "24px" }} />,
    link: "/withdraw",
  },
];

export function AdminMenu() {
  return (
    <Space size={28} direction="vertical">
      {items.map((item) => (
        <MenuItem
          key={item.key}
          label={item.label}
          icon={item.icon}
          redirect={item.link}
        ></MenuItem>
      ))}
    </Space>
  );
}
