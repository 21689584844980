import { createSlice } from "@reduxjs/toolkit";

interface Withdraw {
  withdraw_id: string;
  status: "new" | "paid";
  amount: number;
  user: any;
}

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: () => {
    return {
      isLoading: true,
      withdrawList: [] as Withdraw[],
      total: 0,
      offset: 0,
    };
  },
  reducers: {
    setFetchedWithdrawList: (state, action) => {
      state.withdrawList = action.payload.data;
      state.total = action.payload.total;

      return state;
    },
    setOffset: (state, action) => {
      state.offset = action.payload.offset;

      return state;
    },
    setWidrawAsPaid: (state, action) => {
      if (action.payload.index < state.withdrawList.length)
        state.withdrawList[action.payload.index].status = "paid";

      return state;
    },
  },
});

export const getWithdraw = (state: any) => state.withdraw;

export const { setWidrawAsPaid } = withdrawSlice.actions;

export default withdrawSlice.reducer;
