import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: () => {
    let init = {
      token: null,
      refresh: null,
      loading: false,
    };
    return init;
  },
  reducers: {
    setToken: (state, action) => {
      const { token, refreshToken } = action.payload;
      state.token = token;
      state.refresh = refreshToken;
    },
    logout: (state) => {
      state.token = null;
      state.refresh = null;
    },
  },
});

export const { setToken, logout } = authSlice.actions;

export default authSlice.reducer;

export const getToken = (state: any) => state.auth.token;
