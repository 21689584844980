import { Button } from "antd";

export function useColumns(
  onSetWithdrawPaid: (index: number, withdraw_id: string) => void
) {
  return [
    {
      title: "UUID",
      dataIndex: "withdraw_id",
      key: "withdraw_id",
    },
    {
      title: "E-mail",
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: "Кошелек",
      dataIndex: ["user", "btc_address"],
      key: "wallet",
      render: (text: string, record: any, index: number) => {
        return (
          <div style={{ maxWidth: "148px" }}>
            <u>{text}</u>
          </div>
        );
      },
    },
    {
      title: "Сумма на вывод",
      dataIndex: "amount",
      key: "amount",
      render: (value: any) => {
        return `$ ${value}`;
      },
    },
    {
      title: "Запрос",
      dataIndex: "status",
      key: "status",
      render: (value: any, record: any, index: number) => {
        const style = {
          width: "140px",
          heigth: "40px",
          fontSize: "16px",
          borderColor: "#71A6BE",
          padding: "8px 16px",
          height: "40px",
        };

        if (value === "new") {
          return (
            <Button
              style={{ ...style, backgroundColor: "#ffffff" }}
              onClick={async () => {
                await onSetWithdrawPaid(index, record.withdraw_id);
              }}
            >
              Закрыть запрос
            </Button>
          );
        } else {
          return (
            <Button
              style={{
                ...style,
                backgroundColor: "#71A6BE",
                color: "#ffffff",
              }}
            >
              Закрыт
            </Button>
          );
        }
      },
    },
  ];
}
