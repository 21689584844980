import { Row, Col, Button, Space } from "antd";
import icon from "assets/logo/logo.svg";
import moonIcon from "assets/moon/moon.fill.svg";
import "./style.css";

export function Header() {
  return (
    <div
      style={{
        height: "84px",
        display: "flex",
        verticalAlign: "center",
        padding: "16px 32px",
      }}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{ height: "100%", width: "100%" }}
      >
        <Col>
          <img src={icon} alt="logo" />
        </Col>
        <Col>
          <Space size={16} align="center">
            <Space size={5}>
              <div className="theme-selector-button theme-selector-button-selected">
                Ru
              </div>
              <div style={{ fontSize: "22px" }}>/</div>
              <div className="theme-selector-button">Eng</div>
            </Space>
            <img src={moonIcon}></img>
          </Space>
        </Col>
      </Row>
    </div>
  );
}
