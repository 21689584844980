import { Table, Space } from "antd";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetActiveUsersQuery } from "shared/api/privateApi";
import { useEffect, useState } from "react";

function TableHeader() {
  return (
    <div>
      <span
        style={{
          fontSize: "22px",
          color: "#41899F",
          margin: "12px 0",
          display: "block",
        }}
      >
        Данные пользователей
      </span>
    </div>
  );
}

interface IPurchase {
  quantity: number;
  duration: number;
}

const columns = [
  {
    title: "UUID",
    dataIndex: "user_id",
    key: "user_id",
  },
  {
    title: "Номер телефона",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Баланс",
    dataIndex: ["balance", "usd", "total"],
    key: "balance_usd",
    render: (value: any, record: any, index: number) => {
      return `${value} $`;
    },
  },
  {
    title: "Купленная мощность",
    dataIndex: "purchase",
    key: "purchase",
    render: (value: IPurchase[], record: any, index: number) => {
      if (!value || !value.length) {
        return "Покупок нет";
      }

      return (
        <>
          {value.map(({ quantity, duration }) => (
            <div>{`${quantity} TH/D - ${duration}D`}</div>
          ))}
        </>
      );
    },
  },
  {
    title: "KYC",
    dataIndex: ["verification", "profile"],
    key: "kyc_verification",
    render: (value: any, record: any, index: number) => {
      if (value === true)
        return <CheckOutlined style={{ fontSize: "30px" }}></CheckOutlined>;
      else return <CloseOutlined style={{ fontSize: "30px" }}></CloseOutlined>;
    },
  },
  {
    title: "Кошелек",
    dataIndex: "btc_address",
    key: "btc_address",
    render: (text: string, record: any, index: number) => {
      return (
        <div style={{ maxWidth: "148px" }}>
          <u>{text}</u>
        </div>
      );
    },
  },
];

export function UserTable() {
  const itemPerPage = 20;

  const [offset, setOffset] = useState<number>(0);

  const { data, refetch, isFetching } = useGetActiveUsersQuery({ offset });

  useEffect(() => {
    refetch();
  }, [offset, refetch]);

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <TableHeader></TableHeader>
        <Table
          style={{
            padding: "0px 16px",
            background: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px 0px #12183A29",
          }}
          dataSource={data ? data.data : []}
          columns={columns}
          pagination={{
            style: { backgroundColor: "#ffffff" },
            pageSize: itemPerPage,
            onChange: (page, pageSize) => {
              setOffset((page - 1) * itemPerPage);
            },
            total: data ? data.total : 0,
          }}
          loading={isFetching}
        ></Table>
      </Space>
    </div>
  );
}
