import { Space } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import { resetToInitialState } from "entities/viewer";

export function LogoutButton() {
  const dispatch = useDispatch();

  return (
    <Space
      size={16}
      style={{ fontSize: "16px", color: "#ffffff" }}
      onClick={() => {
        dispatch(resetToInitialState());
      }}
    >
      <LogoutOutlined></LogoutOutlined>
      <span>Выйти</span>
    </Space>
  );
}
