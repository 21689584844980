import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setViewerTokens, resetToInitialState } from "entities/viewer";

import { API_URL } from "shared/config";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().viewer.jwt.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  let result = (await baseQuery(args, api, extraOptions)) as any;

  if (result?.error?.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");

    const refreshResult = await baseQuery(
      {
        url: "/admin/auth/refresh",
        body: { token: refreshToken },
        method: "POST",
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      const { jwt } = refreshResult.data as any;
      api.dispatch(setViewerTokens(jwt));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(resetToInitialState());
    }
  }

  return result;
};

export const privateApi = createApi({
  reducerPath: "privateApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getActiveUsers: builder.query<any, { offset: number }>({
      query: ({ offset }) => ({
        url: `admin/user/list`,
        method: "POST",
        body: {
          limit: 20,
          offset,
          order: {
            user_id: 1,
          },
        },
      }),
    }),
  }),
});

export const { useGetActiveUsersQuery } = privateApi;
