import { Space, Avatar } from "antd";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

export function ViewerCard() {
  const { email } = useSelector((store: any) => store.viewer.profile);

  return (
    <Space size={8} align="center" direction="vertical">
      <Avatar
        size={52}
        icon={<UserOutlined />}
        style={{ backgroundColor: "#D4EFF8" }}
      />
      <span style={{ color: "#D4EFF8", fontSize: "16px" }}>{email} </span>
    </Space>
  );
}
