import { privateApi } from "shared/api/privateApi";

export const authApiSlice = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getViewerProfile: builder.mutation<any, void>({
      query: () => "admin/profile",
    }),
  }),
});

export const { useGetViewerProfileMutation } = authApiSlice;
