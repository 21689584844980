import { Space } from "antd";
import { useNavigate } from "react-router-dom";

export function MenuItem({
  icon,
  label,
  redirect,
}: {
  icon: React.ReactNode;
  label: string;
  redirect: string;
}) {
  const navigate = useNavigate();

  function navigateTo() {
    navigate(redirect || "/");
  }

  return (
    <Space
      size={16}
      align="center"
      style={{ color: "#FFFFFF", fontSize: "16px", lineHeight: "24px" }}
      onClick={navigateTo}
    >
      {icon}
      {label}
    </Space>
  );
}
