import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setViewerProfile } from "entities/viewer";
import { useGetViewerProfileMutation } from "features/viewer/api";

export const ProtectedRoute = ({
  redirectPath = "/login",
  WrapComponent,
}: {
  redirectPath: string;
  WrapComponent: any;
}) => {
  let location = useLocation();

  const viewer = useSelector((store: any) => store.viewer);

  const dispatch = useDispatch();

  const [getProfile, { isLoading }] = useGetViewerProfileMutation();

  // console.log(data, isLoading, isError, error);

  useEffect(() => {
    if (!viewer.profile_loaded) {
      getProfile()
        .unwrap()
        .then((res) => {
          dispatch(setViewerProfile(res));
        })
        .catch((res) => console.log(res));
    }
  }, [viewer, getProfile, dispatch]);

  if (!viewer.jwt.token) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  return (
    <WrapComponent>
      <Outlet />
    </WrapComponent>
  );
};
