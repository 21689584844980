import { Space, Form, Button, Input } from "antd";
import loginLogo from "assets/logo/login-logo.png";
import { useLoginMutation } from "shared/api/api";
import { setViewerProfile, setViewerTokens } from "entities/viewer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export function LoginPage() {
  const [form] = Form.useForm();

  const [login, { isLoading }] = useLoginMutation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#EBF5F9",
        padding: "36px",
      }}
    >
      <div
        style={{
          borderRadius: "16px",
          backgroundColor: "#FFFFFF",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Space size={240}>
            <Space
              size={32}
              direction="vertical"
              styles={{ item: { width: "100%" } }}
              style={{ minWidth: "461px" }}
            >
              <h1 style={{ padding: 0, margin: 0, fontSize: "36px" }}>
                Авторизация
              </h1>
              <Form
                onFinish={async (data) => {
                  try {
                    const { profile, jwt }: any = await login({
                      ...data,
                    }).unwrap();

                    dispatch(setViewerProfile(profile));
                    dispatch(setViewerTokens(jwt));

                    navigate("/users");
                  } catch (e) {
                    form.setFields([
                      {
                        name: "submit",
                        errors: ["Неверный логин и пароль"],
                      },
                    ] as any);
                    console.log(e);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    form.submit();
                  }
                }}
                form={form}
              >
                <div style={{ padding: "12px 0" }}>
                  <Space
                    size={32}
                    direction="vertical"
                    styles={{ item: { width: "100%" } }}
                    style={{ minWidth: "100%" }}
                  >
                    <Form.Item
                      // noStyle
                      style={{ margin: 0, padding: 0 }}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите ваш email",
                        },
                      ]}
                    >
                      <Input
                        style={{ height: "56px", fontSize: "16px" }}
                        placeholder="Введите e-mail"
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      // noStyle
                      style={{ margin: 0, padding: 0 }}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите пароль",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{ height: "56px", fontSize: "16px" }}
                        placeholder="Введите пароль"
                      ></Input.Password>
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        //   height: "24px",
                      }}
                    >
                      <u
                        style={{
                          fontSize: "16px",
                          color: "#232627",
                          lineHeight: "24px",
                        }}
                      >
                        Забыли пароль?
                      </u>
                    </div>
                  </Space>
                </div>
                <Form.Item name="submit">
                  <Button
                    block
                    style={{
                      height: "52px",
                      fontSize: "22px",
                      backgroundColor: "#41899F",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      form.submit();
                    }}
                    loading={isLoading}
                  >
                    Войти
                  </Button>
                </Form.Item>
              </Form>
            </Space>
            <img src={loginLogo} alt="logo"></img>
          </Space>
        </div>
      </div>
    </div>
  );
}
