import { Table, Space } from "antd";
import { getWithdraw, setWidrawAsPaid } from "entities/withdraw/slice";
import { useDispatch, useSelector } from "react-redux";
import { useColumns } from "./useColumns";

import {
  useGetWithdrawListQuery,
  useSetWithdrawStatusMutation,
} from "shared/api/withdrawApi";

import { useEffect, useState } from "react";

function TableHeader() {
  return (
    <div>
      <span
        style={{
          fontSize: "22px",
          color: "#41899F",
          margin: "12px 0",
          display: "block",
        }}
      >
        Запросы на выводы
      </span>
    </div>
  );
}

export function WithdrawTable() {
  const itemPerPage = 20;

  const dispatch = useDispatch();

  const [offset, setOffset] = useState<number>(0);

  const { data, refetch, isFetching } = useGetWithdrawListQuery({ offset });

  const [setAsPaid] = useSetWithdrawStatusMutation();

  useEffect(() => {
    refetch();
  }, [offset, refetch]);

  const columns = useColumns(async (index, withdraw_id) => {
    if (withdraw_id) {
      const res = (await setAsPaid({ withdraw_id })) as any;
    }
  });

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <TableHeader></TableHeader>
        <Table
          style={{
            padding: "0px 16px",
            background: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px 0px #12183A29",
          }}
          dataSource={data ? data.data : []}
          columns={columns}
          pagination={{
            style: { backgroundColor: "#ffffff" },
            onChange: (page, pageSize) => {
              setOffset((page - 1) * itemPerPage);
            },
            total: data ? data.total : 0,
            pageSize: itemPerPage,
          }}
          loading={isFetching}
        ></Table>
      </Space>
    </div>
  );
}
